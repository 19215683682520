import React from "react";
import { useLocation, Redirect } from "react-router-dom";

// Route used to detect conversions on marketing side
const CreatedAccount = () => {
  const location = useLocation();
  return <Redirect to={`/checkout${location.search}`} />;
};

export default CreatedAccount;
